import React, { useState, useEffect } from "react";
import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  MapPinIcon,
  UserCircleIcon,
} from "@heroicons/react/20/solid";
import { collection, query, getDocs } from "firebase/firestore";
import { db } from "../firebase";

function NewTimetable() {
  const [timetableData, setTimetableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filters, setFilters] = useState({
    day: "",
    style: "",
    teacher: "",
  });

  useEffect(() => {
    const fetchTimetableData = async () => {
      const q = query(collection(db, "timetable"));
      const querySnapshot = await getDocs(q);
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTimetableData(data);
      setFilteredData(data); // Initially set filtered data to all data

      // Fetch distinct values for each filter field
      const distinctDays = getDistinctValues(data, "day");
      const distinctStyles = getDistinctValues(data, "style");
      const distinctTeachers = getDistinctValues(data, "teacher");

      // Set initial filter values to the first distinct value for each field
      setFilters({
        day: distinctDays.length > 0 ? distinctDays[0] : "",
        style: distinctStyles.length > 0 ? distinctStyles[0] : "",
        teacher: distinctTeachers.length > 0 ? distinctTeachers[0] : "",
      });
    };

    fetchTimetableData();
  }, []);

  useEffect(() => {
    applyFilters();
  }, [filters]); // Whenever filters change, apply filters

  useEffect(() => {
    resetFilters();
  }, [timetableData]); // Whenever timetable data changes, reset filters

  const applyFilters = () => {
    let filtered = timetableData;

    if (filters.day !== "") {
      filtered = filtered.filter((item) => item.day === filters.day);
    }

    if (filters.style !== "") {
      filtered = filtered.filter((item) => item.style === filters.style);
    }

    if (filters.teacher !== "") {
      filtered = filtered.filter((item) => item.teacher === filters.teacher);
    }

    setFilteredData(filtered);
  };

  const resetFilters = () => {
    setFilters({
      day: "",
      style: "",
      teacher: "",
    });
  };

  const groupByDay = () => {
    const groupedData = {};
    filteredData.forEach((item) => {
      if (!groupedData[item.day]) {
        groupedData[item.day] = [];
      }
      groupedData[item.day].push(item);
    });

    // Sort classes within each day grouping by orderNumber
    Object.keys(groupedData).forEach((day) => {
      groupedData[day].sort((a, b) => a.orderNumber - b.orderNumber);
    });

    return groupedData;
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };

  const getDistinctValues = (data, field) => {
    const values = data.map((item) => item[field]);
    return [...new Set(values)];
  };

  const groupedData = groupByDay();

  const dayMappings = {
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    adhoc: "Monthly ad-hoc",
  };

  const classMappings = {
    ashtangaYoga: "Ashtanga Yoga",
    easySessions: "Easy sessions",
    anusaraInspired: "Anusara inspired Yoga",
    vinyasaFlow: "Vinyasa Flow",
    hathaFusionFlow: "Hatha Fusion Flow",
    vinyasaFlowSuitableAllLevels: "Vinyasa Flow (Suitable for all levels)",
    classicalHatha: "Classical Hatha",
    parentAndMovingBabyYoga: "Parent and Moving Baby Yoga",
    mindfulYoga: "Mindful Yoga",
    mixedAbilityKundalini: "Mixed Ability Kundalini",
    mixedAbilityKundaliniNew: "Mixed Ability Kundalini Yoga - *NEW*",
    mumAndBabyYogaPreCrawling: "Mum and Baby Yoga (pre-crawling)",
    hathaYoga: "Hatha Yoga",
    acroYoga: "Acro Yoga",
    slowFlowBeginnersWelcome: "slow flow (beginners welcome)",
    sunriseYogaRachel: "Sunrise Yoga with Rachel",
    beginnersHathaFlow: "Beginners/refreshers Hatha Flow",
    pregnancyYoga: "Pregnancy Yoga",
    namasteBarnYogaMeditationStudio:
      "Namaste Barn Yoga and Meditation Studio & Retreat - £10 or NHS discount",
    yinYangYoga: "Yin Yang Yoga",
    vinyasaFlowIntermediate: "Vinyasa Flow (intermediate)",
    sunSalutations: "Sun Salutations",
    integratedHathaYoga: "Integrated Hatha Yoga",
    kindCreativeYoga: "Kind and Creative Yoga",
    restorativeYinYogaMonthly: "Restorative Yin Yoga - Monthly",
    shaktiFlowingYogaSession: "Shakti Flowing Yoga Session",
    onceAMonthFridayMarch8th: "Once a month Friday (March 8th)",
    yinYogaSessionsJoy: "Yin Yoga sessions with Joy (every third Friday)",
  };

  const teacherMappings = {
    natalieTaylor: "Natalie Taylor",
    katieHancock: "Katie Hancock",
    emmaBrett: "Emma Brett",
    jadeFranklin: "Jade Franklin",
    ginaHayes: "Gina Hayes",
    chloeHay: "Chloe Hay",
    janeMackarness: "Jane Mackarness",
    laraStone: "Lara Stone",
    jamesRussell: "James Russell",
    billWood: "Bill Wood",
    marthaChester: "Martha Chester",
    joyPrater: "Joy Prater",
    rachelHowell: "Rachel Howell",
    pippaGriffin: "Pippa Griffin",
    maiteAlonso: "Maite Alonso",
    justinDalton: "Justin Dalton",
    helenBodily: "Helen Bodily",
    rowanDaly: "Rowan Daly",
  };

  const styleMappings = {
    ashtanga: "Ashtanga",
    gentleHatha: "Gentle Hatha",
    anusara: "Anusara",
    vinyasa: "Vinyasa",
    integral: "Integral",
    scaravelli: "Scaravelli",
    kundalini: "Kundalini",
    mumAndBaby: "Mum & Baby",
    acro: "Acro",
    hatha: "Hatha",
    pregnancyYoga: "Pregnancy Yoga",
    yinYoga: "Yin Yoga",
    shakti: "Shakti",
  };

  const locationMappings = {
    moveForwardHighStreet: "Move Forward - 16 High Street",
    chapelHouseTotnes: "Chapel House Studios, Totnes",
    marldonVillageHall: "Marldon Village Hall Devon",
    angelHallBowden: "The Angel Hall, Bowden House",
    birdwoodHouse: "Birdwood House",
    angelSouthStreet: "The Angel, South Street, TOTNES",
    greenflowPark: "Greenflow - 1c Chrisina Park",
    namasteBarnStudio: "Namaste Barn Yoga and Meditation Studio - TQ13 0BH",
    ashpringtonVillageHall: "Ashprington Village Hall",
    derekDogYogaCentre: "Derek The Dog Yoga Centre - Queens Way - Exeter",
    spiritAshStudio: "Spirit Ash Yoga Studio, Ashburton",
    dartmouthFlavelCentre: "Dartmouth Flavel Centre",
    stokeGabrielScoutHut: "Stoke Gabriel Scout Hut",
    online: "Online",
    angelHallBowdenPhone: "The Angel Hall, Bowden House, 07726523129",
    stMarysTotnes: "St Marys Church Hall, Totnes",
    theGlade: "The Glade",
  };

  return (
    <div className="mt-8 flow-root">
      <div className="grid grid-cols-4 gap-4 mt-8">
        <label className="block text-sm font-medium text-gray-700">
          Filter by Day:
          <select
            name="day"
            value={filters.day}
            onChange={handleFilterChange}
            className="mt-1 block w-full border rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:outline-none focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Filter by Day</option>

            {getDistinctValues(timetableData, "day")
              .sort((a, b) => {
                // Compare the position of 'a' and 'b' in dayMappings object
                return (
                  Object.keys(dayMappings).indexOf(a) -
                  Object.keys(dayMappings).indexOf(b)
                );
              })
              .map((day) => (
                <option key={day} value={day}>
                  {dayMappings[day]}
                </option>
              ))}
          </select>
        </label>
        <label className="block text-sm font-medium text-gray-700">
          Filter by Style:
          <select
            name="style"
            value={filters.style}
            onChange={handleFilterChange}
            className="mt-1 block w-full border rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:outline-none focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Filter by Style</option>
            {getDistinctValues(timetableData, "style")
              .sort()
              .map((style) => (
                <option key={style} value={style}>
                  {styleMappings[style]}
                </option>
              ))}
          </select>
        </label>
        <label className="block text-sm font-medium text-gray-700">
          Filter by Teacher:
          <select
            name="teacher"
            value={filters.teacher}
            onChange={handleFilterChange}
            className="mt-1 block w-full border rounded-md shadow-sm focus:ring focus:ring-indigo-200 focus:outline-none focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Filter by Teacher</option>
            {getDistinctValues(timetableData, "teacher")
              .sort()
              .map((teacher) => (
                <option key={teacher} value={teacher}>
                  {teacherMappings[teacher]}
                </option>
              ))}
          </select>
        </label>
        <button
          className="rounded-md bg-kycpurple px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={resetFilters}
        >
          Reset Filters
        </button>
      </div>

      <div>
        {Object.keys(groupedData)
          .sort((a, b) => {
            const dayA = dayMappings[a];
            const dayB = dayMappings[b];
            return (
              Object.values(dayMappings).indexOf(dayA) -
              Object.values(dayMappings).indexOf(dayB)
            );
          })
          .map((day) => (
            <div key={day}>
              <div className="my-4 border-b-2">
                <h2 className="text-lg font-semibold text-kycolive">
                  {dayMappings[day]}
                </h2>
              </div>
              {groupedData[day].map((item) => (
                <div
                  key={item.id}
                  className="overflow-hidden rounded-lg bg-white shadow"
                >
                  <div className="px-4 py-5 sm:p-6">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-kycolive px-4 py-2 text-left text-sm font-medium text-white hover:bg-kycgreen focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                            <span className="text-white">{item.time}</span>
                            <span className="text-white">
                              {item.classTitle}
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-white`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-kycolive">
                            <div className="flex justify-between items-center">
                              <p className="flex items-center">
                                <MapPinIcon className="h-4 w-4 mr-1" />{" "}
                                {locationMappings[item.location]}
                              </p>
                              <p>{styleMappings[item.style]}</p>
                              <p className="flex items-center">
                                <UserCircleIcon className="h-4 w-4 mr-1" />{" "}
                                {teacherMappings[item.teacher]}
                              </p>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
}

export default NewTimetable;
